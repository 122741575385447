import {
  NAICS3111,
  NAICS3112,
  NAICS3113,
  NAICS3114,
  NAICS3115,
  NAICS3116,
  NAICS3117,
  NAICS3118,
  NAICS3119,
  NAICS3121,
  NAICS3123,
} from "./images";

export const IconSetName = {
  NAICS3111: NAICS3111(),
  NAICS3112: NAICS3112(),
  NAICS3113: NAICS3113(),
  NAICS3114: NAICS3114(),
  NAICS3115: NAICS3115(),
  NAICS3116: NAICS3116(),
  NAICS3117: NAICS3117(),
  NAICS3118: NAICS3118(),
  NAICS3119: NAICS3119(),
  NAICS3121: NAICS3121(),
  NAICS3123: NAICS3123(),
};

export const TableTitleForEducationProgram = [
  { name: "alberta", code: "AB", languageKey: "educationalProgram.tabProvinceAndTerritory.tableColumnHeading.AB" },
  { name: "british columbia", code: "BC", languageKey: "educationalProgram.tabProvinceAndTerritory.tableColumnHeading.BC" },
  { name: "manitoba", code: "MB", languageKey: "educationalProgram.tabProvinceAndTerritory.tableColumnHeading.MB" },
  { name: "new brunswick", code: "NB", languageKey: "educationalProgram.tabProvinceAndTerritory.tableColumnHeading.NB" },
  { name: "newfoundland and labrador", code: "NFL", languageKey: "educationalProgram.tabProvinceAndTerritory.tableColumnHeading.NFL" },
  // { name: "northwest territories", code: "NT", languageKey: "educationalProgram.tabProvinceAndTerritory.tableColumnHeading.NWT" },
  { name: "nova scotia", code: "NS", languageKey: "educationalProgram.tabProvinceAndTerritory.tableColumnHeading.NS" },
  { name: "nunavut", code: "NU", languageKey: "educationalProgram.tabProvinceAndTerritory.tableColumnHeading.NU" },
  { name: "ontario", code: "ON", languageKey: "educationalProgram.tabProvinceAndTerritory.tableColumnHeading.ON" },
  { name: "prince edward island", code: "PE", languageKey: "educationalProgram.tabProvinceAndTerritory.tableColumnHeading.PE" },
  { name: "quebec", code: "QC", languageKey: "educationalProgram.tabProvinceAndTerritory.tableColumnHeading.QC" },
  { name: "saskatchewan", code: "SK", languageKey: "educationalProgram.tabProvinceAndTerritory.tableColumnHeading.SK" },
  { name: "yukon", code: "YU", languageKey: "educationalProgram.tabProvinceAndTerritory.tableColumnHeading.YU" },
];

export const ColorsForSector = {
  3111: "#de9b61",
  3112: "#fbb518",
  3113: "#d460a2",
  3114: "#ef4b32",
  3115: "#27ade3",
  3116: "#e91c4d",
  3117: "#113f70",
  3118: "#663300",
  3119: "#119448",
  3121: "#804795",
  3123: "#0f5b2e",
};

export const PaginationTableColumns = {
  labels:['Institution Name', 'Program Name', 'Duration', 'Language Of Instruction', 'Level', 'City', 'Province', "Urls"],
  fields:['institutionName', 'programName', 'duration', 'languageOfInstruction', 'level', 'city', 'province', "urls"]
};

export const educationFields = [
  { name: "Province and Territory", value: "provinceTerritory", languageKey: "comparisonInputValue.provinceTerritory" },
  { name: "Level of Instruction", value: "levelInstruction", languageKey: "comparisonInputValue.levelInstruction" },
  { name: "Apprenticeships", value: "apprenticeOffered", languageKey: "comparisonInputValue.apprenticeships" },
]

export const employmentFields = [
  { name: "Province and Territory", value: "provinceTerritory", languageKey: "comparisonInputValue.provinceTerritory" },
  { name: "Sector", value: "sector", languageKey: "comparisonInputValue.sector" },
  { name: "Year", value: "year", languageKey: "comparisonInputValue.year" },
  { name: "Breakdown", value: "breakdown", languageKey: "comparisonInputValue.breakdown" }
]
