import React, { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";

import TableViewEmploymentData from "./EmploymentDataComponents/TableViewEmploymentData";
import HorizontalChartEmploymentBySector from "./EmploymentDataComponents/HorizontalChartEmploymentBySector";
import RaceChart from "./EmploymentDataComponents/RaceChart";
import SemiPieChartView from "./EmploymentDataComponents/SemiPieChartView";
import SemiPieChartViewImmigrants from "./EmploymentDataComponents/SemiPieChartViewImmigrants";
import PyramidChart from "./EmploymentDataComponents/PyramidChart";
import LineChart from "./charts/LineChart";
import Comparison from "../pages/dashboard/Comparison.tsx"
import { useTranslation } from "react-i18next";

const TabForEmployment = ({getTabData, getTab}) => {
  const { t } = useTranslation()
  const [key, setKey] = useState(JSON.parse(localStorage.getItem('user'))?.settings?.employment?.tab); // State to manage active tab

  const handleTab = (k) => {
    setKey(k)
    getTabData('tabcomponent',k)
    getTab(k)
  }

  return (
    <Tabs id="controlled-tab" activeKey={key} onSelect={handleTab} className="custom_tab">
    <Tab eventKey="tab1" title={t('employmentInsights.tabTitle.provinceAndTerritory')} className="tab_container mt-2 p-3">
      {key === 'tab1' && <TableViewEmploymentData tabSettings={getTabData} />}
    </Tab>
    <Tab eventKey="tab2" title={t('employmentInsights.tabTitle.totalEmploymentOverTime')} className="tab_container mt-2 p-3">
      {key === 'tab2' && <LineChart tabSettings={getTabData} />}
    </Tab>
    <Tab eventKey="tab3" title={t('employmentInsights.tabTitle.breakdown')} className="tab_container mt-2 p-3">
      {key === 'tab3' && <PyramidChart tabSettings={getTabData} />}
    </Tab>
    <Tab eventKey="tab4" title={t('commonTitleComparison')} className="tab_container mt-2 p-3">
      {key === 'tab4' && <Comparison field={'employmentInsights'} />}
    </Tab>
  </Tabs>
  );
};

export default TabForEmployment;
