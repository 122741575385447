import React, { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import TableViewEducationalProgram from "./EducationalProgramTabComponents/TableViewEducationalProgram";
import HorizontalChartEducationalProgram from "./EducationalProgramTabComponents/HorizontalChartEducationalProgram";
import TableByInstitution from "./EducationalProgramTabComponents/TableByInstitution";
import TableByApprenticeship from "./EducationalProgramTabComponents/TableByApprenticeship";
import Comparison from "../pages/dashboard/Comparison.tsx"
import { useTranslation } from "react-i18next";


const TabComponent = ({ colData, countByGeography, areaOfStudy, programCountByAreaOfStudy, getTabData }) => {
  const { t } = useTranslation()
  const [key, setKey] = useState(JSON.parse(localStorage.getItem('user')).settings.educationalPrograms.tab || "tab1"); // State to manage active tab

  const handleTab = (k) => {
    setKey(k)
    getTabData('tabcomponent', k)
  }

  return (
    <>
      <Tabs id="controlled-tab" activeKey={key} onSelect={(k) => handleTab(k)} className="custom_tab">
        <Tab eventKey="tab1" title={t('educationalProgram.tabTitle.provinceAndTerritory')} className="tab_container mt-2 p-3">
          {key === "tab1" && (
            <TableViewEducationalProgram colData={colData} countByGeography={countByGeography} tabSettings={getTabData} />
          )}
        </Tab>
        <Tab eventKey="tab2" title={t('educationalProgram.tabTitle.areaOfStudy')} className="tab_container mt-2 p-3">
          {key === "tab2" && (
            <HorizontalChartEducationalProgram />
          )}
        </Tab>
        <Tab eventKey="tab3" title={t('educationalProgram.tabTitle.levelOfInstruction')} className="tab_container mt-2 p-3">
          {key === "tab3" && (
            <TableByInstitution />
          )}
        </Tab>
        <Tab eventKey="tab4" title={t('educationalProgram.tabTitle.apprenticeshipOffered')} className="tab_container mt-2 p-3">
          {key === "tab4" && (
            <TableByApprenticeship />
          )}
        </Tab>
        <Tab eventKey="tab5" title={t("commonTitleComparison")} className="tab_container mt-2 p-3">
          {key === "tab5" && (
            <Comparison field={'educationalInsights'} />
          )}
        </Tab>
      </Tabs>

    </>
  );
};

export default TabComponent;
