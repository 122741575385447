import React, { useEffect, useState, useRef, Children } from 'react';
import { useTranslation } from 'react-i18next';

export const MultiSelectCheckbox = ({ options, selected, onChange, multiSelectRef, maxHeight = '500px' }) => {
    const { t } = useTranslation()
    const collapseRef = useRef<(HTMLDivElement | HTMLButtonElement | null)[]>([]);
    const [newOptions, setOptions] = useState<any>([]);
    // const [selectedSector, setSelectedSector] = useState(selected)
    const [initialLoading, setInitialLoading] = useState(true)

    const handleClearAll = () => {
        setOptions([...options]);
        const updatedOptions = [...options];
        updatedOptions.forEach((parent) => {
            parent.checked = false;
            parent.children.forEach((child) => {
                child.checked = false;
            });
        });
        // collapseRef.current.forEach(collapseElement => {
        //     const targetId = collapseElement?.getAttribute('data-bs-target');
        //     const targetCollapse = document.querySelector(targetId);
        //     targetCollapse.classList.remove('show'); // Collapse all items
        // });
        // collapseRef.current.forEach(collapseElement => {
        //     collapseElement?.classList?.add('collapsed'); // Add collapsed class to all buttons
        // });
        collapseRef.current.forEach(collapseElement => {
            const targetId = collapseElement?.getAttribute('data-bs-target');
            if (targetId) {
                const targetCollapse = document.querySelector(targetId);
                if (targetCollapse) {
                    targetCollapse.classList.remove('show'); // Collapse all items
                }
            }
        });
        
        collapseRef.current.forEach(collapseElement => {
            if (collapseElement) {
                collapseElement.classList.add('collapsed'); // Add collapsed class to all buttons
            }
        });
        // onChange([]);
    };

    const handleParentCheckboxChange = (event, index) => {
        event.stopPropagation();
        const updatedOptions = [...newOptions];
        updatedOptions[index].checked = !updatedOptions[index].checked;

        updatedOptions[index].children.forEach((child) => {
            child.checked = updatedOptions[index].checked;
        });
        setOptions(updatedOptions);
        onChange(updatedOptions)
    };

    const handleChildCheckboxChange = (index, childIndex) => {
        const updatedOptions = [...options];
        updatedOptions[index].children[childIndex].checked = !updatedOptions[index].children[childIndex].checked;
        const allChildrenChecked = updatedOptions[index].children.every(child => child.checked);
        updatedOptions[index].checked = allChildrenChecked;
        setOptions(updatedOptions);
        onChange(updatedOptions);
    };

    useEffect(() => {

        if (options && options.length > 0 && selected) {
            const newOptions = options?.map((item) => {
                // Check if the current item has a parent in selected.parents
                const hasParent = selected?.parents?.find((parent) => parent?._id === item?._id);

                // If it has a parent, add checked: true, otherwise return the item unchanged
                return hasParent ? { ...item, checked: true, children: item.children.map(child => { return { ...child, checked: true } }) } : item;
            });
            setOptions(newOptions)
            // onChange(newOptions)
            setInitialLoading(false)
        }
    }, [options])

    useEffect(() => {
        multiSelectRef.current = handleClearAll
    }, [multiSelectRef, handleClearAll])

    return (
        <div className="multi-select-container mt-3">
            <div className="flex-space-between">
                <h3>{t('commonHeadingSectors')}</h3>
                {/* <button className="clear_btn" onClick={handleClearAll}>{t('commonSubHeadingClearAll')}</button> */}
            </div>

            <div className="container mt-2 p-0">
                <div className="accordion" id="accordionPanelsStayOpenExample">
                    {newOptions.map((option, index) => (
                        <div className="accordion-item">
                            <div className="accordion-header flex-space-between" id={`panelsStayOpen-heading${index}`}>

                                <div><input className="form-check-input chkAll"
                                    id={`chkAccordion${index}All`}
                                    checked={option.checked || false}
                                    onChange={(e) => handleParentCheckboxChange(e, index)}
                                    onClick={(e) => e.stopPropagation()}
                                    type="checkbox" value="" />
                                    <label style={{ display: "contents" }} htmlFor={`chkAccordion${index}All`}>{t(option.languageKey)}</label>
                                </div>
                                <button className={`accordion-button accordion-width collapsed p-0`} type="button" data-bs-toggle="collapse" data-bs-target={"#panel-" + index}
                                    aria-expanded={option.checked ? 'true' : 'false'} aria-controls={`panelsStayOpen-collapse-${index}`}
                                    id="accordion1btn"
                                    ref={el => collapseRef.current[index] = el}>
                                </button>
                            </div>
                            <div id={"panel-" + index} className={`accordion-collapse collapse`} aria-labelledby="panelsStayOpen-headingOne">
                                {/* accordion-body  */}
                                <div className="ms-3">
                                    {option.children.map((subOption, childIndex) => (
                                        <div className="form-check m-1 p-0">
                                            <input className="form-check-input" type="checkbox" value=""
                                                id={`chkAccordion${index}Child${childIndex}`}
                                                checked={subOption.checked || false}
                                                onChange={() => handleChildCheckboxChange(index, childIndex)}
                                            />
                                            <label htmlFor={`chkAccordion${index}Child${childIndex}`}>{t(subOption.languageKey)}</label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}

                </div>
            </div>

            {/* <Accordion>
                {options.map((option, index) => (
                    <div key={index}>
                        <Accordion.Toggle as={CustomToggle} eventKey={index.toString()} isAccordionOpen={isAccordionOpen[index]} toggleAccordion={toggleAccordion}>
                            {option?.name || "null"}
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={index.toString()}>
                            <div>
                                {option.children.map(subOption => (
                                    <div key={subOption._id}>
                                        <label className="sub-option-label">
                                            <input
                                                type="checkbox"
                                                className="checkbox-input"
                                                value={subOption._id}
                                                checked={selectedOptions.includes(subOption._id)}
                                                onChange={() => handleCheckboxChange(subOption._id)}
                                            />
                                            {subOption.name}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </Accordion.Collapse>
                    </div>
                ))}
            </Accordion> */}
            {/* {options.map(option => (

                  <div key={option._id}>
                    <label className="option-label" onClick={() => handleToggleOption(option._id)}>
                        {expandedOptions.includes(option._id) ? '-' : '+'}
                        <input
                            type="checkbox"
                            className="checkbox-input"
                            checked={selectedOptions.includes(option._id)}
                            onChange={(e) => handleCheckboxChange(option._id, e.target.checked)}
                        />
                        {option.name}
                    </label>
                    {expandedOptions.includes(option._id) && option.children.map(subOption => (
                        <div key={subOption._id}>
                            <label className="sub-option-label">
                                <input
                                    type="checkbox"
                                    className="checkbox-input"
                                    value={subOption._id}
                                    checked={selectedOptions.includes(subOption._id)}
                                    onChange={() => handleCheckboxChange(subOption._id)}
                                />
                                {subOption.name}
                            </label>
                        </div>
                    ))}
                </div>
            ))} */}
        </div >
    );
};


// export default MultiSelectCheckbox;