import React from "react";
import { useNavigate, useLocation } from "react-router";
import logoblack from "../../../assets/fpsc-logo.png";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";
import { ReactComponent as GlobeIcon } from "../../../assets/icons/GeoIcon.svg";
import { ReactComponent as SectorIcon } from "../../../assets/icons/SectorIcon.svg";
import { ReactComponent as ComparsionIcon } from "../../../assets/icons/ComparsionIcon.svg";
import { ReactComponent as EducationIcon } from "../../../assets/icons/EducationIcon.svg";
import { ReactComponent as DocumentIcon } from "../../../assets/icons/DocumentIcon.svg";
import { ReactComponent as GearIcon } from "../../../assets/icons/GearIcon.svg";
import LanguageSelector from "../../../components/LanguageSelector";
import { ReactComponent as LogoutIcon } from "../../../assets/icons/LogoutIcon.svg";
import { Link } from "react-router-dom";
import { useIntercom } from "react-use-intercom";

const Sidebar = () => {
  const decodedToken = jwtDecode(localStorage.getItem("token")!);
  const navigate = useNavigate();
  const location = useLocation();
  const { shutdown } = useIntercom()
  const { t } = useTranslation()

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
    shutdown()
  };

  return (
    <>
      <div className="sidebar-wrapper" style={{ display: "flex", flexDirection: "column" }}>
       
        <a href="/" className="text-decoration-none sidebar_logo">
          <img src={logoblack} alt="" style={{ width: "154px" }} />
        </a>
       
        <div style={{ flexGrow: 1, display:"flex", justifyContent: "space-between", flexDirection:"column"}}>
        <div>
          <ul className="nav nav-pills flex-column mb-auto">
            <li><LanguageSelector /></li>
            <li className="nav-item">
              <Link
                to="/establishment-data"
                className={`nav-link ${location.pathname === "/establishment-data" ? "active" : ""}`}
                aria-current="page"
              >
                <GlobeIcon className="icon" />
                {t("sidebarEstablishmentInsights")}
              </Link>
            </li>
            <li>
              <Link
                to="/educational-program"
                className={`nav-link ${location.pathname === "/educational-program" ? "active" : ""}`}
                aria-current="page"
              >
                <EducationIcon className="icon" />
                {t('sidebarEducationalProgramsInsights')}
              </Link>
            </li>
            <li>
              <Link
                to="/employment-data"
                className={`nav-link ${location.pathname === "/employment-data" ? "active" : ""}`}
                aria-current="page"
              >
                <SectorIcon className="icon" />
                {t("sidebarWorkforceInsights")}
              </Link>
            </li>
            {decodedToken && (decodedToken as any).role === "ADMIN" && (
              <li>
                <Link
                  to="/documents"
                  className={`nav-link ${location.pathname === "/documents" ? "active" : ""}`}
                  aria-current="page"
                >
                  <DocumentIcon className="icon" />
                  {t('sidebarDocuments')}
                </Link>
              </li>
            )}

          </ul>
        </div>

        <div className="sibebar_footer" style={{marginBottom:"30px"}}>
          <ul className="nav nav-pills flex-column mb-0">
            <li className="nav-item">
              <Link
                to="/settings"
                className={`nav-link ${location.pathname === "/settings" ? "active" : "link-dark"}`}
                aria-current="page"
              >
                <GearIcon className="icon" />
                {t('sidebarSettings')}
              </Link>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                role="button"
                tabIndex={0}
                onClick={handleLogout}
              >
                <LogoutIcon className="icon" />
                {t('sidebarLogout')}
              </a>
            </li>
          </ul>
        </div>
        </div>
      </div>

    </>
  );
};

export default Sidebar;
