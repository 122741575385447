import React, { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { jwtDecode } from "jwt-decode";
import AddUser from "../pages/settings/AddUser.tsx";
import ChangePassword from "../pages/settings/ChangePassword.tsx";
import { useTranslation } from "react-i18next";
import TableSetting from "../pages/settings/TableSetting.tsx";
// import TableViewEducationalProgram from "./EducationalProgramTabComponents/TableViewEducationalProgram";
// import HorizontalChartEducationalProgram from "./EducationalProgramTabComponents/HorizontalChartEducationalProgram";
// import TableByInstitution from "./EducationalProgramTabComponents/TableByInstitution";
// import TableByApprenticeship from "./EducationalProgramTabComponents/TableByApprenticeship";

const TabComponent = () => {
  const { t } = useTranslation()
  const decodedToken = jwtDecode(localStorage.getItem("token"));
  const tab = decodedToken?.role ==='ADMIN' ? 'tab1' : 'tab2'
  const [key, setKey] = useState(tab); // State to manage active tab
  
  return (
    <Tabs className="custom_tab" id="controlled-tab" activeKey={key} onSelect={(k) => setKey(k)}>
      {decodedToken && decodedToken?.role === "ADMIN" && (
        <Tab eventKey="tab1" title={t('settings.tabTitle.users')} className="tab_container mt-2">
          <AddUser />
        </Tab>
      )}
      <Tab eventKey="tab2" title={t('settings.tabTitle.changePassword')} className="tab_container mt-2">
        <ChangePassword />
      </Tab>
      {decodedToken && decodedToken?.role === "ADMIN" && (
      <Tab eventKey="tab3" title={t("settings.tabTitle.tableSettings")}>
        <TableSetting />
      </Tab>
      )}
      {/* <Tab eventKey="tab4" title="By Apprenticeship Offered">
        <TableByApprenticeship />
      </Tab> */}
    </Tabs>
  );
};

export default TabComponent;
