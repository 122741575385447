import React, { useEffect, useState } from "react";
import API_URLS from "../../constants/apiUrls.ts";
import httpClient from "../../services/httpClient.ts";
import { OverlayTrigger, Tooltip, Table } from "react-bootstrap";
import { capitalizeFirstLetter } from "../../services/commonFn";
import { useTranslation } from "react-i18next";
import PaginationComponent from "./PaginationComponent.js";

const TableByApprenticeship = () => {
  const { t } = useTranslation()
  const [data, setData] = useState([]);
  const [studyField, setStudyField] = useState('')
  const [selectedProvince, setSelectedProvince] = useState('')
  const [showPaginationComponent, setShowPaginationComponent] = useState(false)

  const handlePaginationData = (studyFieldId, provinceId) => {
    setStudyField(studyFieldId)
    setSelectedProvince(provinceId)
    setShowPaginationComponent(true)
  }

  useEffect(() => {
    let url = API_URLS.eductaionalProgramByApprenticeship;

    httpClient({
      method: "get",
      url,
    }).then(({ result, error }) => {
      if (result) {
        let resultantArr = [];
        let iterableArr = result.counts;
        for (let i of iterableArr) {
          let obj1 = {};
          obj1["title"] = capitalizeFirstLetter(t(i.fieldOfLanguageKey));
          obj1["id"] = i.fieldOfStudyId
          obj1["rowSpan"] = i.geographies.length;
          for (let g in i.geographies) {
            let obj2 = {};
            obj2["id"] = i.fieldOfStudyId
            obj2["province"] = capitalizeFirstLetter(
              t(i.geographies[g].geographyLanguageKey)
            );
            obj2["provinceId"] = i.geographies[g].geographyId
            obj2["count"] = i.geographies[g].count;
            resultantArr.push(Number(g) === 0 ? { ...obj1, ...obj2 } : obj2);
          }
        }
       
        setData(resultantArr);
      }
    });
  }, [t]);

  return (
    <>
      {showPaginationComponent ?
        (<>
          <button
            onClick={() => setShowPaginationComponent(!showPaginationComponent)}
            className="btn btn-link text-theme font-small ps-2 m-0"
          >
            {t("educationalProgram.tabApprenticeOffered.paginationComponent.backButtonTable")}

          </button>
          <PaginationComponent
            // onBackClicked={onBackClicked}
            item={studyField}
            // showTable={showTable}
            apprentice={true}
            province={selectedProvince}
          />
        </>)
        :
        <Table responsive bordered className="m-0">
          <h6 className="py-3 px-2">
            {t("educationalProgram.tabApprenticeOffered.title")}
          </h6>
          <tbody>
            {data.map((item, index) => (
              <>
                {item.title && (
                  <>
                    <tr key={index}>
                      <td
                        colSpan={2}
                        style={{ paddingLeft: "20px", textAlign: "left", backgroundColor: '#e41b2c', color: '#fff' }}
                      >
                        <h6 style={{ marginBottom: "0 !important" }}>
                          {item.title}
                        </h6>
                      </td>
                    </tr>
                    <tr>
                      <th style={{ paddingLeft: "20px" }}>
                        {t(
                          "educationalProgram.tabApprenticeOffered.tableColumnHeading.ProvinceTerritory"
                        )}
                      </th>
                      <th style={{ textAlign: "center" }}>
                        {t(
                          "educationalProgram.tabApprenticeOffered.tableColumnHeading.Programs"
                        )}
                      </th>
                    </tr>
                  </>
                )}
                {item.title && (
                  <tr>
                    <OverlayTrigger
                      key={index}
                      delay={{ hide: 450, show: 300 }}
                      overlay={(props) => (
                        <Tooltip {...props}>
                          {t("clickForProgramDetails")}
                        </Tooltip>
                      )}
                      placement="bottom"
                    >
                      <td style={{ paddingLeft: "20px", cursor: "pointer" }} onClick={() => handlePaginationData(item, item.provinceId)}>{item.province}</td>
                    </OverlayTrigger>
                    <td style={{ textAlign: "center", fontWeight: "bold" }}>
                      {item.count}
                    </td>
                  </tr>
                )}
                {!item.title && (
                  <tr key={index}>
                    <OverlayTrigger
                      key={index}
                      delay={{ hide: 450, show: 300 }}
                      overlay={(props) => (
                        <Tooltip {...props}>
                          {t("clickForProgramDetails")}
                        </Tooltip>
                      )}
                      placement="bottom"
                    >
                      <td style={{ paddingLeft: "20px", cursor: "pointer" }} onClick={() => handlePaginationData(item, item.provinceId)}>{item.province}</td>
                    </OverlayTrigger>
                    <td style={{ textAlign: "center", fontWeight: "bold" }}>
                      {item.count}
                    </td>
                  </tr>
                )}
              </>
            ))}
          </tbody>
        </Table>
      }
    </>
  );
};

export default TableByApprenticeship;
