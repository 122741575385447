import React, { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";

import TableViewEmploymentData from "./EmploymentDataComponents/TableViewEmploymentData";
import HorizontalChartEmploymentBySector from "./EmploymentDataComponents/HorizontalChartEmploymentBySector";
import RaceChart from "./EmploymentDataComponents/RaceChart";
import SemiPieChartView from "./EmploymentDataComponents/SemiPieChartView";
import SemiPieChartViewImmigrants from "./EmploymentDataComponents/SemiPieChartViewImmigrants";
import PyramidChart from "./EmploymentDataComponents/PyramidChart";
import LineChart from "./charts/LineChart";
import Comparison from "../pages/dashboard/Comparison.tsx"
import Dashboard from "../pages/dashboard/Dashboard.tsx"
import Dashboard2 from "../pages/dashboard/Dashboard2.tsx"
import { useTranslation } from "react-i18next";

const TabEstablishment = ({getTabData}) => {
  const { t } = useTranslation()
  const [key, setKey] = useState(JSON.parse(localStorage.getItem('user'))?.settings?.establishment?.tab);
  const [state, setState] = useState(null)

  const handleTab = (k) => {
    setKey(k)
    getTabData('tabcomponent',k)
  }

  const handleSectorData = (value) => {
    setState(value)
    setKey('tab2')
    getTabData('tabcomponent','tab2')
  }

  return (
    <Tabs id="controlled-tab" activeKey={key} onSelect={handleTab}  className="custom_tab">
      <Tab eventKey="tab1" title={t("workforceInsights.tabTitle.provinceAndTerritory")} className="tab_container mt-2 p-3">
        {key === "tab1" && <Dashboard handleSectorData={handleSectorData}/>}
      </Tab>
      <Tab eventKey="tab2" title={t("workforceInsights.tabTitle.sector")} className="tab_container mt-2 p-3">
        {key === "tab2" && <Dashboard2 state={state}/>}
      </Tab>
      <Tab eventKey="tab3" title={t("commonTitleComparison")} className="tab_container mt-2 p-3">
        {key === "tab3" && <Comparison field={'establishmentCount'} tabSettings={getTabData}/>}
        
      </Tab>
    </Tabs>
  );
};

export default TabEstablishment;