import React, { useState } from "react";
import { useForm } from "react-hook-form";

import Form from "react-bootstrap/esm/Form";
import logo from "../../assets/Logo.png";
import logoblack from "../../assets/fpsc-logo.png";
import httpClient from "../../services/httpClient.ts";
import loginGraphic from '../../assets/login-graphic.png';
// import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import API_URLS from "./../../constants/apiUrls.ts";
import styles from "./Login.module.css";
import { Toast, ToastContainer } from "react-bootstrap";
import { useIntercom } from "react-use-intercom";
const Login = () => {

    const {
        register,
        clearErrors,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({ mode: "all" });
    const navigate = useNavigate();
    const { boot } = useIntercom()
    const [errorMessage, setErrorMessage] = useState(null);

    // Function to show dynamic error message
    const showError = (message) => {
        setErrorMessage(message);
    };

    const handleLogin = (data) => {
        let url = API_URLS.login;
        httpClient({
            method: "post",
            url,
            data,
        }).then(({ result, error }) => {
            if (result) {
                localStorage.setItem("user", JSON.stringify(result?.user));
                localStorage.setItem("token", result?.token);
                navigate("/establishment-data");
                reset();
                boot({name: result?.user?.name})
            } else {
                showError(error.message);
            }
        }).catch((error) => {
            console.error("Error:", error);
            showError(error.message);
        }).finally(() => {
        });
    };
    return (
        <>
        <div className={styles.login_container}>
          <div className={styles.login_header}>
            <div className="container-fluid">
              <img src={logoblack} alt="" style={{ width: "154px" }} />
            </div>
          </div>
          <div className={`container`}>
            <ToastContainer
              style={{ top: "20px", right: "20px" }}
              className="toast-error"
            >
              {errorMessage && (
                <Toast
                  onClose={() => setErrorMessage(null)}
                  show={true}
                  delay={3000}
                  autohide
                >
                  <Toast.Header>
                    <strong className="me-auto">Error</strong>
                  </Toast.Header>
                  <Toast.Body>{errorMessage}</Toast.Body>
                </Toast>
              )}
            </ToastContainer>
            <div className="row  no-gutters">
              <div
                className={`align-items-center bg-white col-md-6 d-flex`}
              >
                  <div className={styles.login_form}>
                <div className="brand  form-style">
                  <h1>
                    Effortlessly upload and oversee your documents all in one
                    place.
                  </h1>
                  <p className={styles.login_form_info}>
                    Welcome! Please enter your email and password
                  </p>
  
                  <Form className="mb-5" onSubmit={handleSubmit(handleLogin)}>
                    <div className="custom-form-group">
                      <label>Email *</label>
                      <input
                        type="text"
                        placeholder="Enter your email address"
                        className="c-form-control c-form-control-lg"
                        id="InputEmail"
                        aria-describedby="emailHelp"
                        {...register("email", {
                          required: {
                            value: true,
                            message: "Required",
                          },
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Invalid email address",
                          },
                        })}
                      />
  
                      {errors.email && (
                        <div className="invalid-feedback">
                          {errors.email?.message}
                        </div>
                      )}
                    </div>
                    <div className="custom-form-group">
                      <label>Password *</label>
                      <input
                        type="password"
                        placeholder="Enter Password"
                        className="c-form-control c-form-control-lg"
                        id="InputPassword"
                        {...register("password", {
                          required: "Required",
                          // pattern: {
                          //     value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                          //     message: 'Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, 1 special character, and be at least 8 characters long',
                          // },
                        })}
                      />
                      {errors.password && (
                        <div className="invalid-feedback">
                          {errors.password.message}
                        </div>
                      )}
                    </div>
                    <button type="submit" className="button">
                      Login
                    </button>
                  </Form>
                </div></div>
              </div>
              <div className="col-md-6">
                  <img src={loginGraphic} className="img-fluid float-end" alt="login graphic"/>
              </div>
            </div>
          </div>
        </div>
      </>
    )
}

export default Login;