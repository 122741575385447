
// import { ToastContainer } from 'react-toastify';
import React, { useEffect } from 'react';
import './App.css';
import Navigation from "./navigation/index.jsx";
import { useIntercom } from 'react-use-intercom';

function App() {
  document.title = 'Food Processing';
  const { boot } = useIntercom()
  
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    boot({ name: user?.name })
  }, [boot])

  return (
    <>
      <Navigation></Navigation>
    </>
  );
}

export default App;