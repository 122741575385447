import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import API_URLS from "../../constants/apiUrls.ts";
import httpClient from "../../services/httpClient.ts";
import { useTranslation } from "react-i18next";
import { MultiSelect }  from "react-multi-select-component";
import { Loader } from "../Loader.tsx";

const LineChart = ({ tabSettings }) => {
  const { t } = useTranslation()
  const [showLoader, setShowLoader] = useState(false)
  const [initialDataLoading, setInitialDataLoading] = useState(false)
  const [colors, setColors] = useState([
    {sector: "Animal food manufacturing", color: "#de9b61"}, //  3111:
    {sector: "Grain and oilseed milling", color: "#fbb518"}, // 3112:
    {sector: "Sugar and confectionery product manufacturing", color: "#d460a2"}, // 3113:
    {sector: "Fruit and vegetable preserving and specialty food manufacturing", color: "#ef4b32"}, // 3114:
    {sector: "Dairy product manufacturing", color: "#27ade3"}, // 3115:
    {sector: "Meat product manufacturing", color: "#e91c4d"}, // 3116:
    {sector: "Seafood product preparation and packaging", color: "#113f70"}, // 3117:
    {sector: "Bakeries and tortilla manufacturing", color: "#663300"}, // 3118:
    {sector: "Other food manufacturing", color: "#119448"}, // 3119:
    {sector: "Beverage manufacturing", color: "#804795"}, // 3121:
    {sector: "Cannabis product manufacturing", color: "#0f5b2e"}, // 3123:
  ]);

  const [options, setOptions] = useState({
    title: {
      text: "",
    },
    legend: {
      enabled: true, // Disable the legend
    },
    
    credits: {
      enabled: false,
    },
    xAxis: {
      title: "Year",
      categories: ["2021", "2022", "2023", "2024"],
    },
    yAxis: {
      title: {
        text: t("employmentInsights.tabProvinceAndTerritory.seriesName.employmentCount"),
      },
    },
    plotOptions: {
      // series: {
      //   color: "#E61E28",
      // },
      series: {
        marker: {
          symbol: 'circle',
          // color: "#E61E28",
          // radius: 6,
          // lineWidth: 3,
          // lineColor: '#ffd200'
        },
        // tooltip:{
        //   pointFormat: `{point.name} ${t("count")} {point.y}`
        // }
      }
    },
    series: [
      {
        data: [0, 0, 0, 0],
      },
    ],
  });

  const [establishment, setEstablistment] = useState([]);
  const [selectedEstablishment, setSelectedEstablistment] = useState(JSON.parse(localStorage.getItem('user'))?.settings?.employment?.years?.establishment || 'all');
  const [multiEstablishments, setMultiEstablishments] = useState([])

  useEffect(() => {
    try {
      setShowLoader(true)
    let url = API_URLS.listEstablishments.replace(false, true);
   
    httpClient({
      method: "get",
      url,
    }).then(({ result, error }) => {
      if (result) {
        if (result.establishments) {
          const filteredEstablishment = result.establishments.filter(item => item.name !== 'Total, all industries')
        
          let establishments = filteredEstablishment.map(item => {return {label: t(item.languageKey), value: item._id, languageKey: item.languageKey}})
          establishments.splice(0,0,{label:t("overallFoodIndustry"), value:"all", languageKey:"overallFoodIndustry"})
          setEstablistment([...establishments]);
          setMultiEstablishments([establishments[0]])
          setShowLoader(false)
        }
      }
    });
    } catch (error) {
      console.log(error)
    } finally {
      setInitialDataLoading(true)
    }
    
  }, []);

  useEffect(() => {
   
      setEstablistment((prev) =>
        prev.map((item) => ({
          ...item,
          label: t(item.languageKey),
        }))
      );
    
      setMultiEstablishments((prev) =>
        prev.map((item) => ({
          ...item,
          label: t(item.languageKey),
        }))
      );

    
  }, [t]);

  useEffect(() => {
    if (initialDataLoading && multiEstablishments.length !== 0) {
      try {
        const establishments = multiEstablishments.length > 0 ? multiEstablishments.map(item => item.value) : ''
      let url = API_URLS.employmentCountPerYear.replace(
        "{establishment}",
        establishments
      );
      setShowLoader(true)
      httpClient({
        method: "get",
        url,
      }).then(({ result, error }) => {
        if (result) {
          const totalArr = [];
          const categories = [];
          
          if(multiEstablishments.length > 0){
            
            for (let i of result.count) {
              const dataArray = []
              for (let d of i.yearlyCount){
                dataArray.push(d.totalCount)
                categories.push(d.year);
              }
              if(i?.name){
                const color = colors.find(c => c.sector.toLowerCase() === i.name.toLowerCase())
                totalArr.push({name:t(i.languageKey),data:dataArray,color:color.color})
              }else{
                totalArr.push({name:t("overallFoodIndustry"), data:dataArray, color:"#E61E28"})
              }
            }
           
          }else{
            const dataArray = []
            for (let i of result.count) {
              dataArray.push(i.totalCount);
              categories.push(i.year);
            }
            totalArr.push({name:t("overallFoodIndustry"), data:dataArray, color:"#E61E28"})
          }
         
          setOptions((prevState) => {
            const newData = {
              ...prevState,
              xAxis: { ...prevState.xAxis, categories: categories },
              series: totalArr,
              tooltip: {
                enabled: true,
                formatter: function () {
                  return "<b>" + this.x + "</b><br/>" + `${t("count")}: ` + this.y.toLocaleString('en-CA');
                },
              },
              yAxis:{
                title: {
                  text: t("employmentInsights.tabProvinceAndTerritory.seriesName.employmentCount")
              }
            }
            };
            return newData;
          });
        }
      });
      } catch (error) {
        console.log(error)
      } finally {
        setShowLoader(false)
      }
      
    }
  }, [establishment, selectedEstablishment,multiEstablishments,t]);

  const handleMultiEstablishmentsChange = (selected) => {
   
    if (selected?.length == 0 ) {
      setMultiEstablishments([]);
      setOptions((prevState) => {
        const newData = {
          ...prevState,
          series: [], // Ensure this correctly targets the series in your state
        };
        
        return newData;
      });
    } else {
      setMultiEstablishments(selected);
    }
    
  };
  return (
    <>
    <Loader show={showLoader}/>
      <div className="row gx-0 pt-3">
        {/* <div className="col-md-4">
          <div className="custom-form-group custom-form-group-sm ps-2">
            <label htmlFor="yearSelect" className="text-black">
              {t('employmentInsights.tabYears.inputTitle.selectEstablishment')}
            </label>
            <select
              className="form-select c-form-control c-form-control-bg c-form-control-sm c-form-control-sm-height fw-semibold"
              value={selectedEstablishment}
              onChange={handleEstChange}
            >
              <option value={"all"} selected>{t('employmentInsights.tabYears.inputValue.overallFoodIndustry')}</option>
              {establishment.map((item) => (
                <option key={item._id} value={item._id}>
                  {t(item.languageKey)}
                </option>
              ))}
            </select>
          </div>
        </div> */}
        <div className="col-md-4">
          <div className="custom-form-group custom-form-group-sm ps-2">
            <label htmlFor="yearSelect" className="text-black">
              {t('employmentInsights.tabYears.inputTitle.bySector')}
            </label>
            <MultiSelect
              options={establishment}
              value={multiEstablishments}
              onChange={handleMultiEstablishmentsChange}
              hasSelectAll = {false}
              labelledBy={"Select"}
              // overrideStrings={{
              //   "allItemsAreSelected": "",  // Optionally, clear the "Select All" label
              //   "selectAll": "",  // This removes the "Select All" text
              // }}
            />
          </div>
        </div>
      </div>
      <div className="mt-4">
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
    </>
  );
};

export default LineChart;
