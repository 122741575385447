import React, { useEffect } from "react";
import ReactDOM from 'react-dom';
import Highcharts from "highcharts";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { capitalizeString } from "../../services/commonFn";
import { FaInfoCircle } from 'react-icons/fa';
import i18n from 'i18next';

const PieChart = ({ dataset, title, color }) => {
  const { t } = useTranslation()
  // const disclaimer = 'Any data that shows as “unavailable” when you navigate our interactive dashboard is data that has been suppressed by Statistics Canada. Statistics Canada supresses data for a variety of reasons, including the obstacles that are sometimes encountered when dealing with the limited availability of data for the food processing industry in some parts of the country.'

  useEffect(() => {
    // Define the chart configuration
    const pieChartData = [];
    for (let d of dataset) {
      pieChartData.push([capitalizeString(t(d.languageKey)), d.totalCount]);
    }
    const options = {
      chart: {
        type: "pie",
        events: {
          load: function () {
            const chart = this;
            const iconContainerId = 'icon-container';
            const tooltipId = 'icon-tooltip';

            const addIconWithTooltip = () => {
              // Remove the existing icon and tooltip if they exist
              let existingIconContainer = document.getElementById(iconContainerId);
              if (existingIconContainer) existingIconContainer.remove();

              let existingTooltip = document.getElementById(tooltipId);
              if (existingTooltip) existingTooltip.remove();

              // Create a div for the icon
              let iconDiv = document.createElement('div');
              iconDiv.id = iconContainerId;
              iconDiv.className = 'custom-icon';

              // Create a div for the custom tooltip
              // let tooltipDiv = document.createElement('div');
              // tooltipDiv.id = tooltipId;
              // tooltipDiv.innerHTML = t("disclaimerPieChart");
              // tooltipDiv.classList.add('pie-chart-tooltip');

              // Ensure the chart container exists before appending elements
              if (chart.container) {
                // Set chart container position to relative
                chart.container.style.position = 'relative';
                chart.container.appendChild(iconDiv);
              }

              // Render the React icon inside the div
              try {
                ReactDOM.render(
                  <CustomIconWithTooltip
                    iconContainerId={iconContainerId}
                    tooltipId={tooltipId}
                  />,
                  iconDiv
                );;
                // ReactDOM.render(<FaQuestionCircle />, iconDiv2);
              } catch (error) {
                console.error("Error rendering the icons:", error);
              }

              // Add event listeners to show and hide the tooltip
              // iconDiv.addEventListener('mouseover', () => {
              //   // let { x, y } = iconDiv.getBoundingClientRect();
              //   tooltipDiv.style.right = `10px`;
              //   tooltipDiv.style.top = `38px`;
              //   tooltipDiv.style.visibility = 'visible';
              // });

              // iconDiv.addEventListener('mouseout', () => {
              //   tooltipDiv.style.visibility = 'hidden';
              // });

              // i18n.on('languageChanged',(lng)=>{
              //   tooltipDiv.innerHTML = t("disclaimerPieChart");
              // })

              // Set the icon's position in the top-right corner
              const updateIconPosition = () => {
                if (chart.container) {
                  const legendBBox = chart.legend.group.getBBox();  // Get the bounding box of the legend
                  const iconPadding = 10; // Padding between the chart and the icon

                  // Position the icon between the chart and legend
                  iconDiv.style.right = '25px'; // Adjust padding from right as needed
                  iconDiv.style.bottom = `${legendBBox.height + iconPadding}px`;
                }
              };

              updateIconPosition();
              Highcharts.addEvent(chart, 'redraw', updateIconPosition);
              window.addEventListener('resize', updateIconPosition);
            };
            addIconWithTooltip();
          }
        }
      },
      credits: {
        enabled: false,
      },
      title: {
        text: title,
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: false,
          },
          showInLegend: true,
        },
      },
      series: [
        {
          name: t("establishments"),
          data: pieChartData,
        },
      ],
    };
    const generateColors = (color) => {
      const shades = [];
      const steps = 11; // Number of shades
      for (let i = 0; i < steps; i++) {
        const shade = new Highcharts.Color(color)
          .brighten(i / steps + 0.1)
          .get(); // Adjust brightness
        shades.push(shade);
      }
      return shades;
    };
    // Render the chart
    Highcharts.setOptions({
      colors: generateColors(color),
      lang: {
        thousandsSep: ",",
      },
    });
    Highcharts.chart("pie-chart-container", options);
  }, [dataset, title, t]);

  return (
    <div id="pie-chart-container">{/* Chart will be rendered here */}</div>
  );
};

export default PieChart;

const CustomIconWithTooltip = ({ iconContainerId, tooltipId }) => {
  const { t } = useTranslation()
  return (
    <>
      {/* First Icon with Tooltip */}
      <div id={`${iconContainerId}-1`} className="custom-icon" style={{ position: 'relative' }}>
        <OverlayTrigger
          placement="bottom"
          overlay={(props) => (
            <Tooltip {...props} className="tooltip-justify">
              {/* <div style={{background: "#fff", borderRadius: "15px", width: "100%", padding:"15px", marginBottom:"5px"}}>
                    <img src={FPSCLogo} alt="" width={"100%"} height={100}/>
                </div> */}
              {/* <b>{t("disclaimer")}:</b>   */}
              {t("disclaimerPieChart").split('\n').map((line, index, array) => (
                <React.Fragment key={index}>
                  {line}
                  {index < array.length - 1 && (
                    <div style={{ height: '7px' }} />)}
                </React.Fragment>
              ))}
            </Tooltip>
          )}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '4px',
              width: "30px",
              height: "30px",
              backgroundColor: "#f0f0f0",
              cursor: 'pointer'
            }}
          >
            <FaInfoCircle style={{ fontSize: '20px', color: "#212529" }} />
          </div>
        </OverlayTrigger>
      </div>

      {/* Second Icon with Tooltip */}
      {/* <div id={`${iconContainerId}-2`} className="custom-icon" style={{ position: 'relative' }}>
        <OverlayTrigger
          placement="bottom"
          overlay={(props) => (
            <Tooltip {...props} className="tooltip-justify">
              {t("helpTextMap")}
            </Tooltip>
          )}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '4px',
              width: "30px",
              height: "30px",
              backgroundColor: "#f0f0f0",
              cursor: 'pointer'
            }}
          >
            <FaQuestionCircle style={{ fontSize: '20px', color: "#212529" }} />
          </div>
        </OverlayTrigger>
      </div> */}
    </>
  );
};
