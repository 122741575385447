import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FaTrash, FaPenToSquare } from "react-icons/fa6";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CommonHeader from "../../components/Header.tsx";
import httpClient from "../../services/httpClient.ts";
import API_URLS from "./../../constants/apiUrls.ts";
// import { jwtDecode } from "jwt-decode";
import { FaPlus } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
const roles = ["ADMIN", "SUBADMIN", "USER"];

const AddUser = () => {
  const { t } = useTranslation()
  // const decodedToken = jwtDecode(localStorage.getItem("token"));
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({ mode: "all" });
 
  const [usersData, setUsersData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)

  // This function is for opening user form modal
  const openModal = () => {
    setShowModal(true);
  };

  // This function is for closing user form modal
  const closeModal = () => {
    setShowModal(false);
  };

  // This function add data in form hook
  const handleUserData = (user) => {
    setValue("_id", user._id)
    setValue("name", user.name)
    setValue("email", user.email)
    setValue("role", user.role)
  };

  // This function add new user and update existing user in db
  const handleAddAndUpdateUser = (data) => {
    data?._id
      ? httpClient({
          method: "post",
          url: API_URLS.updateUser,
          data,
        })
          .then(({ result, error }) => {
            if (result) {
              closeModal();
              reset();
              fetchAllUsersData();
              toast.success("User updated successfully");
            } else {
              toast.error(error);
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          })
      : httpClient({
          method: "post",
          url: API_URLS.addUser,
          data,
        })
          .then(({ result, error }) => {
            
            if (result) {
              closeModal();
              reset()
              fetchAllUsersData();
              toast.success("User added successfully");
            } else {
              toast.error(error.message);
            }
          })
          .catch((error) => {
            toast.error(error);
          });
  };

  // This function delete user in db
  const handleDelete = (id) => {
    httpClient({
      method: "delete",
      url: API_URLS.deleteUser,
      data: { id },
    })
      .then(({ result, error }) => {
        if (result) {
          setShowConfirmationModal(false);
          fetchAllUsersData();
          setSelectedUser(null);
          toast.success("User removed successfully");
        } else {
          toast.error(error);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  // This function fetch all user data from db
  const fetchAllUsersData = async () => {
    httpClient({
      method: "get",
      url: API_URLS.getUsers,
    })
      .then(({ result, error }) => {
        if (result) {
          setUsersData(result?.users);
        } else {
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    fetchAllUsersData();
  }, []);

  return (
    <>
      <ToastContainer />
      <div className="pt-2 ps-2 pe-2">
      <CommonHeader
        title={t("settings.tabUsers.title")}
        component ={"addUser"} 
        ButtonIcon={<FaPlus/>}
        buttonTitle={t("settings.tabUsers.buttonTitle")}
        buttonEventTriggered={openModal}
      />
      </div>
      
      <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">{t('settings.tabUsers.tableColumnHeaderName')}</th>
            <th scope="col">{t('settings.tabUsers.tableColumnHeaderEmail')}</th>
            <th scope="col">{t('settings.tabUsers.tableColumnHeaderRole')}</th>
            <th scope="col">{t('settings.tabUsers.tableColumnHeaderActions')}</th>
          </tr>
        </thead>
        <tbody>
          {usersData &&
            usersData.length &&
            usersData?.map((user, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{user?.name}</td>
                <td>{user?.email}</td>
                <td>{user?.role}</td>
                <td>
                  <FaPenToSquare
                    className="table-action-icon text-primary cursor-pointer me-2"
                    onClick={() => {
                      handleUserData(user);
                      setShowModal(true);
                    }
                  } 
                    
                  />
                  <FaTrash
                    className="table-action-icon text-danger cursor-pointer"
                    onClick={() => {setShowConfirmationModal(true);setSelectedUser(user)}}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <Modal
        show={showModal}
        onHide={() => {closeModal();reset()}}
        // size="lg"
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('settings.tabUsers.modalAddNewUser.title')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Form className="w-100">
              <div className="custom-form-group">
                <label>{t('settings.tabUsers.modalAddNewUser.inputTitle.name')} *</label>
                <input
                  type="text"
                  placeholder="Enter your name"
                  className="form-select c-form-control  fw-semibold c-form-control-sm c-form-control-bg"
                  id="InputEmail"
                  aria-describedby="emailHelp"
                  {...register("name", {
                    required: {
                      value: true,
                      message: "Name required",
                    },
                  })}
                />
                {errors.name && (
                  <div className="invalid-feedback">{errors.name?.message}</div>
                )}
              </div>
              <div className="custom-form-group">
                <label>{t('settings.tabUsers.modalAddNewUser.inputTitle.email')} *</label>
                <input
                  type="text"
                  placeholder="Enter your email address"
                  className="form-select c-form-control  fw-semibold c-form-control-sm c-form-control-bg"
                  id="InputEmail"
                  aria-describedby="emailHelp"
                  {...register("email", {
                    required: {
                      value: true,
                      message: "Email Required",
                    },
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address",
                    },
                  })}
                />

                {errors.email && (
                  <div className="invalid-feedback">
                    {errors.email?.message}
                  </div>
                )}
              </div>
              <div className="custom-form-group">
                <label>{t('settings.tabUsers.modalAddNewUser.inputTitle.role')} *</label>
                <select
                  {...register("role", {
                    required: true,
                  })} // Registering "role" field
                  className="form-select c-form-control  fw-semibold c-form-control-sm c-form-control-bg"
                >
                  <option value=""> -- Select a role -- </option>
                  {roles.map((role) => (
                    <option key={role} value={role}>
                      {role}
                    </option>
                  ))}
                </select>
                {errors.role && (
                  <div className="invalid-feedback">
                    {'Role Required'} {/* Use errors.role.message here */}
                  </div>
                )}
              </div>
              {/* <div className="form-group pb-3">
                <label>Permissions *</label>
                <div className="form-control mt-2">
                  <Form.Check
                    type="checkbox"
                    label="Read"
                    name="checkboxGroup"
                    value={"Read"}
                    id="option1"
                    onChange={handlePermissions}
                    checked={userData.permissions.includes("Read")}
                  />
                  <Form.Check
                    type="checkbox"
                    label="Write"
                    name="checkboxGroup"
                    value={"Write"}
                    id="option2"
                    onChange={handlePermissions}
                    checked={userData.permissions.includes("Write")}
                  />
                  <Form.Check
                    type="checkbox"
                    label="Upload"
                    name="checkboxGroup"
                    value={"Upload"}
                    id="option3"
                    onChange={handlePermissions}
                    checked={userData.permissions.includes("Upload")}
                  />
                </div>
              </div> */}

              {/* <button type="submit" className="btn btn-primary mt-4">
              Save
            </button> */}
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
        <Button className="button button-secondary"  variant={null} onClick={handleSubmit(handleAddAndUpdateUser)}>{t('settings.tabUsers.modalAddNewUser.buttonTitle.save')}</Button>
        <Button className="button"  variant={null} onClick={() => {closeModal();reset()}}>{t('settings.tabUsers.modalAddNewUser.buttonTitle.close')}</Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
        // size="lg"
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('settings.tabUsers.modalConfirmation.title')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
           <h5>{t('settings.tabUsers.modalConfirmation.message')}</h5>
          </div>
        </Modal.Body>
        <Modal.Footer>
        <Button className="button button-secondary"  variant={null} onClick={() => handleDelete(selectedUser._id)}>{t('settings.tabUsers.modalConfirmation.buttonTitle.yes')}</Button>
          <Button className="button"  variant={null} onClick={() => setShowConfirmationModal(false)}>{t('settings.tabUsers.modalConfirmation.buttonTitle.cancel')}</Button>
          
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddUser;
