import axios from "axios";
import ErrorHandlingService from "./errorHandlingService.ts";
const BASE_URL = process.env.REACT_APP_BASE_URL;

// let BASE_URL = 'http://localhost:3030/api/';
// BASE_URL = 'https://fpscapi.chantsit.com/';

const axiosInstance = axios.create({
  // baseURL: `${process.env.BASE_URL}`,
  baseURL: `${BASE_URL}`,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

axiosInstance.interceptors.request.use((config) => {
  let token = localStorage.getItem("token");
  if (token) config.headers.Authorization = `Bearer ${token}`;
  // LoadingState.show();

  return config;
}, ErrorHandlingService.handleRequestError);

axiosInstance.interceptors.response.use((response) => {
  // response.data = transformData(response.data);
  // LoadingState.hide();
  return response;
}, ErrorHandlingService.handleRequestError);

export default axiosInstance;
