
class ErrorHandlingService {
    static handleRequestError(error) {
      console.error('Request error:', error);
      
      throw error;
    }
  
    static handleResponseError(error) {
        
      console.error('Response error:', error);
      
      throw error;
    }
  }
  
  export default ErrorHandlingService;