import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoLanguage } from "react-icons/io5";

function LanguageSelector() {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(localStorage.getItem('selectedLanguage') || 'en')

  const handleLanguageChange = (e) => {
    const selectedLanguage = e.target.value;
    setLanguage(selectedLanguage)
    localStorage.setItem('selectedLanguage', selectedLanguage)
  };

  useEffect(() => {
    const storedLanguage = localStorage.getItem('selectedLanguage');
    if (storedLanguage) {
      setLanguage(storedLanguage);
      i18n.changeLanguage(storedLanguage);
    } else {
      i18n.changeLanguage('en');
    }
  }, [i18n,localStorage.getItem('selectedLanguage')]);

  return (
    <div className="language-select">
      <IoLanguage style={{ fontSize: "1.5rem" }} />
      <select onChange={handleLanguageChange} style={{ width: "100%" }} value={language}>
        <option value='en'>English</option>
        <option value='fr'>Français</option>
      </select>
    </div>
  );
}

export default LanguageSelector;
