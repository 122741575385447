import React from 'react'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CommonHeader from "../../components/Header.tsx";
import TabSettings from '../../components/TabSetting.js';
import { useTranslation } from 'react-i18next';

const Settings = () => {
  const { t } = useTranslation()
  return (
    <>
      <ToastContainer/>
      <CommonHeader
        title={t("settings.headerTitle")}
      />
    <TabSettings/>
    </>
  )
}

export default Settings