import React, { useEffect, useState, useRef } from "react";
import API_URLS from "../../constants/apiUrls.ts";
import httpClient from "../../services/httpClient.ts";
import { Card, ListGroup } from "react-bootstrap";
import { Loader } from "../../components/Loader.tsx";
import PieChart from "../../components/charts/PieChart.tsx";
import BarChartHighChart from "../../components/charts/BarChartHighChart";
import { useTranslation } from "react-i18next";
import { capitalizeString } from "../../services/commonFn.js";

const Dashboard2 = ({state}) => {

  const { t } = useTranslation();
  const [businessTypeList, setBusinessTypeList] = useState<any>([]);
  const [establishmentList, setEstablishmentList] = useState<any>([]);
  const [years, setYears] = useState<any>([]);
  const [geographyList, setGeographyList] = useState<any>([]);
  const [establishmentBusinessCount, setEstablishmentBusinessCount] =
    useState<any>([]);
  const [subEstablishmentBusinessCount, setSubEstablishmentBusinessCount] =
    useState<any>([]);
  const [response, setResponse] = useState<any>([]);
  const [employmentSize, setEmploymentSize] = useState("");
  const [selectedSector, setSelectedSector] = useState("");
  const [selectedBarIndex, setSelectedBarIndex] = useState(undefined);
  const [selectedGeography, setSelectedGeography] = useState("");
  const [selectedPeriod, setSelectedPeriod] = useState(2);
  const [selectedYear, setSelectedYear] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userSetting, setUserSetting] = useState(
    JSON.parse(localStorage.getItem("user") || "")
  );
  const [colors, setColors] = useState<any>([
    "#de9b61", //  3111:
    "#fbb518", // 3112:
    "#d460a2", // 3113:
    "#ef4b32", // 3114:
    "#27ade3", // 3115:
    "#e91c4d", // 3116:
    "#113f70", // 3117:
    "#663300", // 3118:
    "#119448", // 3119:
    "#804795", // 3121:
    "#0f5b2e", // 3123:
  ]);

  const handleEmploymentSizeChange = (event) => {
    const empSize = event.target.value;
    setEmploymentSize(empSize);
  };
  const handleSectorChange = (event) => {
    setSelectedSector(event.target.value);
  };
  const handleGeograpgyChange = (event) => {
    const geography = event.target.value;
    setSelectedGeography(geography);
  };
  const handlePeriodChange = (event) => {
    const period = event.target.value;
    setSelectedPeriod(period);
  };
  const handleYearChange = (event) => {
    const year = event.target.value;
    setSelectedYear(year);
  };

  const clearAll = () => {
    setEmploymentSize("");
    setSelectedSector("");
    setSelectedYear(years[0]);
    setSelectedPeriod(2);
    setSelectedGeography(
      geographyList.filter((geo) => geo.name == "canada")[0]?._id
    );
  };

  const getFilterOptions = async () => {
    setShowLoader(true);
    try {
      const [
        businessTypesResult,
        establishmentsResult,
        geographiesResult,
        yearsResult,
      ] = await Promise.all(
        [
          httpClient({
            method: "get",
            url: API_URLS.listBusinessTypes,
          }),
          httpClient({
            method: "get",
            url: API_URLS.listEstablishments,
          }),
          httpClient({
            method: "get",
            url: API_URLS.listGeographies,
          }),
          httpClient({
            method: "get",
            url: API_URLS.businessCountYearsList,
          }),
        ].map((promise) => promise.then(({ result }) => result))
      );

      const processedResults = {
        businessTypes: businessTypesResult?.businessTypes || [],
        establishments: establishmentsResult?.establishments || [],
        geographies: geographiesResult?.geographies || [],
        years: yearsResult?.years || [],
      };

      if (processedResults.businessTypes.length > 0) {
        setBusinessTypeList(processedResults.businessTypes);
      }

      if (processedResults.establishments.length > 0) {
        setEstablishmentList(processedResults.establishments);
      }

      if (processedResults.geographies.length > 0) {
        const sortedGeographies = processedResults.geographies
          .filter((geo) => geo.name.toLowerCase() !== "geography")
          .sort((a, b) => a["name"].localeCompare(b["name"]));
        const canada = sortedGeographies.filter(
          (geo) => geo.name === "canada"
        );
        const otherProvinces = sortedGeographies.filter(
          (geo) => geo.name !== "canada"
        );
        const finalSortedGeographies = [...canada, ...otherProvinces];
        setGeographyList(finalSortedGeographies);
        setSelectedGeography(canada?.[0]?._id)
      }

      if (processedResults.years.length > 0) {
        setYears(processedResults.years);
      }

      return processedResults; // Return the processed results
    } catch (error) {
      console.log(error);
    } finally {
      setShowLoader(false);
    }
  };

  const filterUpdated = (
    employmentSize,
    selectedPeriod,
    selectedYear,
    selectedGeography
  ) => {
    let filterString: string = "?";
    if (employmentSize) {
      filterString += "businessType=" + employmentSize + "&";
    }
    if (selectedSector) {
      filterString += "establishment=" + selectedSector + "&";
    }
    if (selectedPeriod) {
      filterString += "period=" + selectedPeriod + "&";
    }
    if (selectedYear) {
      filterString += "year=" + selectedYear + "&";
    }
    if (selectedGeography) {
      filterString += "geography=" + selectedGeography + "&";
    }
    filterString = filterString.slice(0, -1);

    getBusinessCountByGeography(filterString);
    getSubEstablishmentData();
  };

  useEffect(() => {
    const fetchData = async () => {
      const result = await getFilterOptions(); // Wait for getFilterOptions to complete

      if (state) {
        const { id, empSize, period, year } = state || {};
        const selectedEmpSize = empSize !== undefined ? empSize : "null";
        const selectedPeriod = period !== undefined ? period : 2;
        const selectedYear = year !== undefined ? year : result?.years[0];
        const selectedGeographyId = result?.geographies.filter(
          (geo) => geo?.name.toLowerCase() === id.toLowerCase()
        )[0]?._id;

        setEmploymentSize(selectedEmpSize);
        setSelectedPeriod(selectedPeriod);
        setSelectedYear(selectedYear);
        setSelectedGeography(selectedGeographyId);

        filterUpdated(
          selectedEmpSize,
          selectedPeriod,
          selectedYear,
          selectedGeographyId
        );
      } else {
        if (userSetting?.settings?.sectorInsights) {
          const empSize = userSetting?.settings?.sectorInsights?.employmentSize;
          const period = parseInt(
            userSetting?.settings?.sectorInsights?.period
          );
          const year = userSetting?.settings?.sectorInsights?.year ? parseInt(userSetting?.settings?.sectorInsights?.year) : result?.years[0];
          const geography = userSetting?.settings?.sectorInsights?.geography;

          setEmploymentSize(empSize);
          setSelectedPeriod(period);
          setSelectedYear(year);
          setSelectedGeography(geography);
          filterUpdated(empSize, period, year, geography);
        } else {
          filterUpdated(
            employmentSize,
            selectedPeriod,
            selectedYear,
            result?.geographies.filter(
              (geo) => geo?.name.toLowerCase() === "canada"
            )[0]?._id
          );
        }
      }
    };
    fetchData();
  }, [state]);

  useEffect(() => {
    let url = API_URLS.preloadedSettings;
    let data = {
      settings: {
        ...userSetting.settings,
        sectorInsights: {
          geography: selectedGeography,
          year: selectedYear,
          period: selectedPeriod,
          employmentSize: employmentSize,
        },
      },
    };
    httpClient({
      method: "put",
      url,
      data,
    }).then(({ result, error }) => {
      if (result) {
        let datatoSave = {
          ...userSetting,
          settings: {
            ...result?.settings,
          },
        };
        localStorage.setItem("user", JSON.stringify(datatoSave));
      }
    });

  }, [selectedYear, selectedPeriod, employmentSize, selectedGeography]);

  const [imageSrc, setImageSrc] = useState(null);
  const getSubEstablishmentData = (index = undefined) => {
    setShowLoader(true);
    setImageSrc(null);
    setSelectedBarIndex(index);

    let filterString: string = "?";
    if (establishmentBusinessCount[index]?._id) {
      filterString +=
        "establishment=" + establishmentBusinessCount[index]?._id + "&";
      import(
        `./../../assets/sector-icons/New folder/NAICS ${establishmentBusinessCount[index]?.externalId}.png`
      )
        .then((module) => {
          setImageSrc(module.default);
        })
        .catch((error) => {
          console.error("Error loading image:", error);
        });
    } else {
      setShowLoader(false);
      setImageSrc(null);
      setSubEstablishmentBusinessCount([]);
      return;
    }
    if (employmentSize) {
      filterString += "businessType=" + employmentSize + "&";
    }
    if (selectedPeriod) {
      filterString += "period=" + selectedPeriod + "&";
    }
    if (selectedYear) {
      filterString += "year=" + selectedYear + "&";
    }
    if (selectedGeography) {
      filterString += "geography=" + selectedGeography + "&";
    }
    filterString = filterString.slice(0, -1);
    let url = API_URLS.businessCountByChildEstablishment + filterString;
    httpClient({
      method: "get",
      url,
    })
      .then(({ result, error }) => {
        if (result) {
          if (result.counts && result.counts.length) {
            setSubEstablishmentBusinessCount(result.counts);
          }
        }
        setShowLoader(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setShowLoader(false);
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  const getBusinessCountByGeography = (filters: string = "") => {
    setShowLoader(true);
    let url = API_URLS.businessCountByParentEstablishment + filters;

    httpClient({
      method: "get",
      url,
    })
      .then(({ result, error }) => {
        if (result) {
          if (result.counts && result.counts.length) {
            setEstablishmentBusinessCount(result.counts);
            const labels = result.counts.map(
              (item) => `${item.externalId}`
            );
            const labels2 = result.counts.map(
              (item) => `NAICS ${item.externalId}`
            );
            const titles = result.counts.map((item) => item.name);
            const values = result.counts.map((item) => item.totalCount);
            const industryNames = result.counts.map((item) => item.languageKey);

            setResponse({ labels, labels2, values, titles, colors, industryNames });
          } else {
            setResponse({});
          }
        }
        setShowLoader(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setShowLoader(false);
      })
      .finally(() => {
        setShowLoader(false);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!isLoading) {
      filterUpdated(
        employmentSize,
        selectedPeriod,
        selectedYear,
        selectedGeography
      );
    }
  }, [selectedPeriod, selectedYear, selectedGeography, employmentSize]);

  return (
    <>
      <Loader show={showLoader} />
    
      <div className="row gx-2">
        <div className="col-xl-9 col-md-8">
          <Card>
            <Card.Body>
              <Card.Title>
                <div className="flex-space-between">
                  <h3>{t("commonHeadingFilters")}</h3>
                    <button className="clear_btn" onClick={clearAll}>
                      {t("commonSubHeadingClearAll")}
                    </button>
                </div>
              </Card.Title>
              <div className="sector_filter_container">
                <div className="row gx-3">
                  <div className="col-xl-3 col-md-6">
                    <div className="custom-form-group">
                      <label
                        htmlFor="employmentSizeSelect"
                        className="text-black"
                      >
                        {t("commonInputTitleEmploymentSize")}:
                      </label>
                      <select
                        className="form-select c-form-control  fw-semibold c-form-control-sm c-form-control-bg"
                        value={employmentSize}
                        onChange={handleEmploymentSizeChange}
                      >
                        <option value={""}>
                          {t("businessTypeTotalWithAndWithoutEmployees")}
                        </option>
                        <option value={"null"}>
                          {t("businessTypeTotalWithoutEmployees")}
                        </option>
                        {businessTypeList.map((type) => (
                          <option value={type._id}>
                            {capitalizeString(t(type.languageKey))}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-6">
                    <div className="custom-form-group">
                      <label htmlFor="GeographySelect" className="text-black">
                        {t("commonInputTitleGeography")}
                      </label>
                      <select
                        className="form-select c-form-control fw-semibold c-form-control-sm  c-form-control-bg"
                        value={selectedGeography}
                        onChange={handleGeograpgyChange}
                      >
                        <option disabled>{t("commonTextSelect")}</option>
                        {geographyList.map((geography) => (
                          <option value={geography._id}>
                            {capitalizeString(t(geography.languageKey))}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-6">
                    <div className="custom-form-group">
                      <label htmlFor="periodSelect" className="text-black">
                        {t("commonInputTitlePeriod")}
                      </label>
                      <select
                        className="form-select  c-form-control fw-semibold c-form-control-sm  c-form-control-bg"
                        value={selectedPeriod}
                        onChange={handlePeriodChange}
                      >
                        <option value={""} disabled>
                          {t("commonTextSelectPeriod")}{" "}
                        </option>
                        <option value={1}>
                          {t("commonPeriodInputValueJune")}
                        </option>
                        <option value={2}>
                          {t("commonPeriodInputValueDecember")}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-6">
                    <div className="custom-form-group">
                      <label htmlFor="yearSelect" className="text-black">
                        {t("commonInputTitleYear")}
                      </label>
                      <select
                        className="form-select  c-form-control fw-semibold c-form-control-sm  c-form-control-bg"
                        value={selectedYear}
                        onChange={handleYearChange}
                      >
                        <option value={""} disabled>
                          {t("commonTextSelectYear")}
                        </option>
                        {
                          years.map((year) => (
                            <option key={year} value={year}>
                              {year}
                            </option>
                          ))
                        }
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <BarChartHighChart
                dataset={response}
                title={t("commonHeadingSectors")}
                barClick={getSubEstablishmentData}
              />
            </Card.Body>
          </Card>
        </div>
        <div className="col-xl-3 col-md-4">
          <Card className="insight_card">
            <Card.Body>
              <Card.Title>
                <h3>{t("commonTitleInsights")}</h3>
              </Card.Title>
              <ListGroup>
                {subEstablishmentBusinessCount?.length > 0 && (
                  <ListGroup.Item
                    style={{
                      paddingLeft: "0",
                      color: "white",
                      backgroundColor:
                        selectedBarIndex !== undefined
                          ? colors[selectedBarIndex]
                          : "#E61E28",
                    }}
                  >
                    {imageSrc && (
                      <div className="flex-start">
                        <img
                          style={{ margin: "5px" }}
                          height={50}
                          src={imageSrc}
                          alt="Sector Icon"
                        />
                        <h5 style={{ alignSelf: "center" }}>
                          <strong>
                            {capitalizeString(
                              t(
                                subEstablishmentBusinessCount[0]
                                  ?.parentLanguageKey
                              )
                            )}{" "}
                            ({t("naics")}{" "}
                            {
                              subEstablishmentBusinessCount[0]
                                ?.parentEstablishmentExternalId
                            }
                            )
                          </strong>
                        </h5>
                      </div>
                    )}
                  </ListGroup.Item>
                )}
                {!!subEstablishmentBusinessCount?.length && (
                  <ListGroup.Item>
                    <PieChart
                      dataset={subEstablishmentBusinessCount}
                      title={t("dashboard2PieChartTitle")}
                      color={
                        selectedBarIndex !== undefined
                          ? colors[selectedBarIndex]
                          : "#E61E28"
                      }
                    />
                  </ListGroup.Item>
                )}
                {subEstablishmentBusinessCount.map((subItem, index) => (
                  <ListGroup.Item key={index}>
                    <strong>
                      {capitalizeString(t(subItem?.languageKey))} ({t("naics")}{" "}
                      {subItem?.establishmentExternalId})
                    </strong>
                    : {subItem.totalCount}{" "}
                    {capitalizeString(t("commonInsightsTextBusinesses"))}
                  </ListGroup.Item>
                ))}
                {!subEstablishmentBusinessCount.length && (
                  <ListGroup.Item>
                    {t("dashboard2InsightsMessage")}
                  </ListGroup.Item>
                )}
              </ListGroup>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Dashboard2;
