
import React, { useRef, useEffect, useState } from 'react';

import API_URLS from './../../constants/apiUrls.ts'
import httpClient from '../../services/httpClient.ts';
import CommonHeader from '../../components/Header.tsx';
import UploadDocumentModal from './components/UploadDocument.tsx';
import { jwtDecode } from 'jwt-decode';
import { FaDownload } from "react-icons/fa6";

import Upload from '../../assets/Upload.svg'
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const Documents = () => {
    const { t } = useTranslation()
    const decodedToken = jwtDecode(localStorage.getItem("token"));
    const [showModal, setShowModal] = useState(false);
    const [documentList, setDocumentList] = useState<any>([]);

    useEffect(() => {
        getDocuments()
    }, [])

    const uploadNewDocument = () => {
        setShowModal(true);
    };

    const getDocuments = () => {
        let url = API_URLS.listDocuments;
        httpClient({
            method: "get",
            url,
        }).then(({ result, error }) => {
           
            if (result) {
                if (result.documents) {
                    setDocumentList([...result.documents])
                }
            }
        })
    }

    const handleClose = () => {
        setShowModal(false);
        getDocuments();
    };

    const formatTypeName = (str) => {
        const words = str.split('_');
        const capitalizedWords = words.map(word => {
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        });
        return capitalizedWords.join(' ');
    }

    const handleDownload = async (fileId, filePath) => {
       
        let url = API_URLS.downloadFile.replace(":fileId", fileId);
        httpClient({
            method: "get",
            url,
            responseType: "blob",
        }).then(({ result, error }) => {
            
            if (result) {
                try {
                    const blob = new Blob([result], { type: result.type });
                    const fileName = filePath.split('_').slice(2).join("_")
                    const url = window.URL.createObjectURL(blob);

                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute("download", fileName);

                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    window.URL.revokeObjectURL(url);
                } catch (error) {
                    console.error('There has been a problem with your fetch operation:', error);
                }
            }
        })
    }


        return (
            <>
                <CommonHeader title={'Documents'} component = {"documents"} buttonTitle={t('document.uploadButtonTitle')} ButtonIcon={Upload} buttonEventTriggered={uploadNewDocument} permissions={decodedToken?.permissions} />
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th scope="col" style={{ width: '30px', borderRadius: '5px 0 0 0' }}>#</th>
                            <th scope="col">{t('document.tableColumnHeaderType')}</th>
                            <th scope="col">{t('document.tableColumnHeaderFileName')}</th>
                            <th scope="col">{t('document.tableColumnHeaderPeriod')}</th>
                            <th scope="col">{t('document.tableColumnHeaderYear')}</th>
                            <th scope="col">{t('document.tableColumnHeaderLastUpdatedBy')}</th>
                            <th scope="col">{t('document.tableColumnHeaderLastUpdated')}</th>
                            <th scope="col">{t('document.tableColumnHeaderLastFileStatus')}</th>
                            <th scope="col" style={{ borderRadius: '0 5px 0 0' }}>{"Actions"}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {documentList.map((document, index) =>
                            <tr>
                                <td scope="row">{index + 1}</td>
                                <td>{formatTypeName(document.type)}</td>
                                <td>{document.file?.originalName}</td>
                                <td>{document.period}</td>
                                <td>{document.year}</td>
                                <td>{document.lastUpdatedBy?.name || document.lastUpdatedBy?.email || '-'}</td>
                                <td>{moment(document.updatedAt).format("YYYY-MM-DD HH:MM:SS A")}</td>
                                <td>{document.isProcessed ? 'Processed' : 'Processing'}</td>
                                <td style={{ cursor: "pointer", paddingLeft: "25px" }} title="Dowload File" onClick={() => handleDownload(document.file._id,document.file.path)}><FaDownload /></td>

                            </tr>)}

                    </tbody>
                </table>
                <UploadDocumentModal show={showModal}
                    onHide={handleClose} />

            </>
        );
    }

    export default Documents;
